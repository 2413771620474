import Axios, { AxiosResponse } from "axios";
import { serverURL } from "../utils/config";
import {
  IAuthAPI,
  IBlogPost,
  ILogin,
  IMlmodel,
  IProfileResult,
  IRefreshToken,
  IRegistration,
} from "./interfaces";
import Cookies from "js-cookie";
import { IApplicationAPI } from "./interfaces/applications";
import { IPersonAPI } from "./interfaces/persons";

export const instance = Axios.create({
  baseURL: serverURL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((config) => {
  let token = Cookies.get("access");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

// Если несколько запросов выполняется одновременно и нужно обновить токен
let isRefreshing = false
let failedQueue: any[] = []

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })
  
  failedQueue = [];
}

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  const originalRequest = error.config;

  if (error.response.status === 403 && !originalRequest._retry) {
      
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({resolve, reject})
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return instance(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        })
      }

    originalRequest._retry = true;
    isRefreshing = true;

    const refreshToken = Cookies.get('refresh');
    return new Promise(function (resolve, reject) {
       instance.post('/auth/jwt/refresh/', { refresh: refreshToken })
        .then(({data}) => {
            Cookies.set('access', data.access)
            instance.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
            processQueue(null, data.token);
            resolve(instance(originalRequest));
        })
        .catch((err) => {
            Cookies.remove("access");
            Cookies.remove("refresh");
            reject(err);
        })
        .finally(() => { isRefreshing = false })
    })
  }

  return Promise.reject(error);
});

// instance.interceptors.response.use(
//   response => response,
//   error => {
//     const originalRequest = error.config;
//     if (error.response.status === 403 && !originalRequest._retry) {

//       // Новый токен уже запросили, ждем ответ
//       if (isRefreshing) {
//         return new Promise(function(resolve, reject) {
//           failedQueue.push({resolve, reject})
//         }).then(token => {
//           originalRequest.headers['Authorization'] = `Bearer ${token}`
//           return Axios(originalRequest)
//         }).catch(err => {
//           return Promise.reject(err)
//         })
//       }

//       originalRequest._retry = true
//       isRefreshing = true

//       const refreshToken = Cookies.get("refresh")
//       if (refreshToken) {
//         return new Promise(function(resolve, reject) {
//           Axios.post('/auth/jwt/refresh/', {refresh: refreshToken})
//           //getApi.refresh({refresh: refreshToken})
//             .then(({data}) => {
//               Cookies.set("access", data.access);
//               instance.defaults.headers.common['Authorization'] = `Bearer ${data.access}`;
//               originalRequest.headers['Authorization'] = `Bearer ${data.access}`
//               processQueue(null, data.access)
//               resolve(Axios(originalRequest))
//             })
//             .catch((err) => {
//               processQueue(err, null)
//               reject(err)
//             })
//             .finally(() => {isRefreshing = false})
//         })
//       }
//     }

//     return Promise.reject(error)
//   }
// )


// instance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     console.log("originalRequest", originalRequest.url)
//     if (error.response.status === 403 && !originalRequest._retry) {
//       console.log("refreshToken", isRefreshing, [...failedQueue], originalRequest.url)

//       if (isRefreshing) {
//         return new Promise((resolve, reject) => {
//           failedQueue.push({resolve, reject})
//         }).then(token => {
//           console.log("after token", originalRequest.url, token)
//           originalRequest.headers['Authorization'] = 'Bearer ' + token;
//           return Axios(originalRequest);
//         }).catch(err => {
//           return Promise.reject(err);
//         })
//       }

//       originalRequest._retry = true;
//       isRefreshing = true

//       const refreshToken = Cookies.get("refresh");
//       if (refreshToken) {
//         await getApi
//           .refresh({ refresh: refreshToken })
//           .then((response) => {
//             console.log("refreshToken then", response)
//             Cookies.set("access", response.data.access);
//             instance.defaults.headers.common[
//               "Authorization"
//             ] = `Bearer ${response.data.access}`;

//             processQueue(null, response.data.access);
//             return instance(originalRequest);
//           })
//           .catch(() => {
//             Cookies.remove("access");
//             Cookies.remove("refresh");
//             processQueue(error, null);
//             return Promise.reject(error);
//           })
//           .finally(() => { isRefreshing = false });
//       }
//     }
//     // if(error.response.status === 401) {
//     //
//     // }
//     return Promise.reject(error);
//   },
// );

export const getApi = {
  register: async (data: IRegistration.Request) => {
    return await instance.post<
      IRegistration.Request,
      AxiosResponse<IRegistration.Response>
    >("/auth/users/", data);
  },
  login: async (data: ILogin.Request) => {
    return await instance.post<ILogin.Request, AxiosResponse<ILogin.Response>>(
      "/auth/jwt/create/",
      data,
    );
  },
  getMe: async () => {
    return await instance.get<{}, AxiosResponse<IAuthAPI.getMe.Response>>(
      "/auth/users/me",
    );
  },
  getBlogPost: async (id: string) => {
    return await instance.get<
      IBlogPost.Request,
      AxiosResponse<IBlogPost.Response>
    >(`/futureprofile_api/blogposts/${id}`);
  },
  getApplicationFields: async (type: number) => {
    return await instance.get<
      {},
      AxiosResponse<IApplicationAPI.getFields.Response>
    >(`/futureprofile_api/mlmodelinputs?person_type=${type}`);
  },
  getApplicationValues: async (personId: string) => {
    return await instance.get<
      {},
      AxiosResponse<IApplicationAPI.getValues.Response>
    >(`/futureprofile_api/profilequestionanswerpairs/?person_id=${personId}`);
  },
  postApplicationFieldData: async (
    data:
      | IApplicationAPI.saveFieldData.Request
      | IApplicationAPI.saveFieldData.Request[],
  ) => {
    return await instance.post<
      IApplicationAPI.saveFieldData.Request,
      AxiosResponse<IApplicationAPI.saveFieldData.Response>
    >(`futureprofile_api/profilequestionanswerpairs/`, data);
  },
  patchApplicationFieldData: async (
    id: string,
    data: IApplicationAPI.saveFieldData.Request,
  ) => {
    return await instance.patch<
      IApplicationAPI.saveFieldData.Request,
      AxiosResponse<IApplicationAPI.saveFieldData.Response>
    >(`futureprofile_api/profilequestionanswerpairs/${id}/`, data);
  },
  // getProfileResults: async () => {
  //   return await instance.get<
  //     IProfileResult.Request,
  //     AxiosResponse<Array<IProfileResult.Response>>
  //   >("/forecast_api/profileresults/");
  // },
  getProfileResultsFirst: async (personIds: string, modelIds: string) => {
    let params = (personIds !== '' ? `&person_id=${personIds}`: '')
      + (modelIds !== '' ? `&mlmodel_id=${modelIds}` : '')

    return await instance.get<
      IProfileResult.Request,
      AxiosResponse<IProfileResult.PagingResponse>
    >(`/forecast_api/profileresults/?page=1${params}`);
  },
  getProfileResultsNext: async (url: string) => {
    const params = url.split('?').length > 1 ? url.split('?')[1]:''
    return await instance.get<
      IProfileResult.Request,
      AxiosResponse<IProfileResult.PagingResponse>
    >(`/forecast_api/profileresults/?${params}`);
  },
  getMlmodels: async () => {
    return await instance.get<
      IMlmodel.Request,
      AxiosResponse<Array<IMlmodel.Response>>
    >("/futureprofile_api/mlmodels/");
  },
  refresh: async (data: IRefreshToken.Request) => {
    return await instance.post<
      IRefreshToken.Request,
      AxiosResponse<IRefreshToken.Response>
    >("/auth/jwt/refresh/", data);
  },
  makeForecast: async (personId: string) => {
    return await instance.get<
      {},
      AxiosResponse<IApplicationAPI.makeForecast.Response>
    >(`/forecast_api/rscriptcall/?person_id=${personId}`);
  },
  getForecastStatus: async (taskId: string) => {
    return await instance.get<
      {},
      AxiosResponse<IApplicationAPI.makeForecast.TaskStatus>
    >(`/forecast/task-status/${taskId}/`)
  },
  getPersons: async () => {
    return await instance.get<
      {},
      AxiosResponse<IPersonAPI.getPersons.Response>
    >("/futureprofile_api/persons/");
  },
  createPerson: async (data: IPersonAPI.createPerson.Request) => {
    return await instance.post<
      IPersonAPI.createPerson.Request,
      AxiosResponse<IPersonAPI.createPerson.Response>
    >("/futureprofile_api/persons/", data);
  },
  editPerson: async (data: IPersonAPI.editPerson.Request) => {
    const id = data.id;
    return await instance.patch<
      IPersonAPI.editPerson.Request,
      AxiosResponse<IPersonAPI.editPerson.Response>
    >(`/futureprofile_api/persons/${id}/`, { avatar_name: data.avatar_name });
  },
  deletePerson: async (id: string) => {
    return await instance.delete(`/futureprofile_api/persons/${id}/`);
  },
};
