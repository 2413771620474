import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getApi from "../../api";
import { AxiosError } from "axios";
import { IProfileResult } from "../../api/interfaces";
import { LoadableReduxData, LoadingStatus } from "../utilityTypes";
import { IApplicationAPI } from "../../api/interfaces/applications";
import { getMessageFromDefaultResponseError } from "../helpers";
import { arrayMove } from "@dnd-kit/sortable";

interface IForecast extends LoadableReduxData {
  result: string;
}

interface IProfileResultSlice {
  status: LoadingStatus
  error: string
  profileResults: Array<IProfileResult.Response> | undefined
  waitUrl?: string
  previous?: string
  next?: string
  loadMoreStatus: LoadingStatus
  filterValue: { person: string[]; model: string[] }
  render: number
  forecast: IForecast
  forecastInProcess: {personId: string, taskId: string}[]
}

export const getProfileResults = createAsyncThunk(
  "profileResults/getProfileResults",
  async (data: {person: string[], model: string[]}, { rejectWithValue }) => {
    try {
      const response = await getApi.getProfileResultsFirst(data.person.join(','), data.model.join(','));
      return response.data;
    } catch (e: any) {
      const err = e as AxiosError<IProfileResult.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

export const loadMoreProfileResults = createAsyncThunk(
  "profileResults/loadMoreProfileResults",
  async (url: string, { rejectWithValue }) => {
    try {
      const response = await getApi.getProfileResultsNext(url);
      return response.data;
    } catch (e: any) {
      const err = e as AxiosError<IProfileResult.Error>;
      return rejectWithValue(err.response?.data);
    }
  }
)

export const makeForecast = createAsyncThunk(
  "profileResults/makeForecast",
  async (personId: string, { rejectWithValue }) => {
    try {
      const response = await getApi.makeForecast(personId);
      return response.data;
    } catch (e) {
      const err = e as AxiosError<IApplicationAPI.makeForecast.Error>;
      return rejectWithValue(err.response?.data);
    }
  },
);

export const getForecastStatus = createAsyncThunk(
  "profileResults/getForecastStatus",
  async (taskId: string, { rejectWithValue }) => {
    try {
      const response = await getApi.getForecastStatus(taskId);
      return response.data;
    } catch (e) {
      const err = e as AxiosError<IApplicationAPI.makeForecast.Error>;
      return rejectWithValue(err.response?.data);
    }
  }
)

const initialState: IProfileResultSlice = {
  status: "idle",
  error: "",
  profileResults: undefined,
  waitUrl: undefined,
  loadMoreStatus: "idle",
  filterValue: { person: [], model: [] },
  render: 0,
  forecast: {
    result: "",
    status: "idle",
    error: "",
  },
  previous: undefined,
  next: undefined,
  forecastInProcess: []
};

const profileResultSlice = createSlice({
  name: "profileResults",
  initialState,
  reducers: {
    filterByPerson(state, action) {
      if (state.profileResults) {
        if (action.payload === '') {
          state.filterValue.person = []
          return
        }
  
        const index = state.filterValue.person.indexOf(action.payload)

        if (index === -1) {
          state.filterValue.person.push(action.payload);
        } else {
          state.filterValue.person.splice(index, 1)
        }
      }
    },
    filterByModel(state, action) {
      if (state.profileResults) {
        if (action.payload === '') {
          state.filterValue.model = []
          return
        }

        const index = state.filterValue.model.indexOf(action.payload)

        if (index === -1) {
          state.filterValue.model.push(action.payload);
        } else {
          state.filterValue.model.splice(index, 1)
        }
      }
    },
    resetFilter(state) {
      state.filterValue = { person: [], model: [] };
    },
    swapProfileResults(state, action) {
      if (state.profileResults !== undefined)
        state.profileResults = arrayMove(state.profileResults, action.payload.from, action.payload.to)
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getProfileResults.fulfilled, (state, action) => {
        if (state.waitUrl === (action.meta.arg.person??'') + '&' + (action.meta.arg.model??'')) {
          state.status = "fulfilled";
          state.profileResults = action.payload.results;
          state.next = action.payload.next
          state.previous = action.payload.previous
          state.waitUrl = undefined
          state.loadMoreStatus = 'fulfilled'
        }
      })
      .addCase(getProfileResults.pending, (state, action) => {
        state.status = "pending";
        state.loadMoreStatus = 'fulfilled'
        state.waitUrl = (action.meta.arg.person??'') + '&' + (action.meta.arg.model??'')
        state.profileResults = undefined
        state.error = "";
      })
      .addCase(getProfileResults.rejected, (state, action) => {
        state.status = "rejected";
        state.loadMoreStatus = 'fulfilled'
        state.waitUrl = undefined
      })
      .addCase(loadMoreProfileResults.fulfilled, (state, action) => {
        if (state.next === action.meta.arg && state.profileResults != undefined && state.loadMoreStatus === 'pending') {
          state.profileResults = state.profileResults?.concat(action.payload.results);
          state.next = action.payload.next
          state.previous = action.payload.previous
          state.loadMoreStatus = "fulfilled"
        }
      })
      .addCase(loadMoreProfileResults.pending, (state) => {
        if (state.loadMoreStatus === 'pending')
          return

        state.loadMoreStatus = "pending";
        state.error = "";
      })
      .addCase(loadMoreProfileResults.rejected, (state, action) => {
        state.loadMoreStatus = "rejected";
      })
      .addCase(makeForecast.fulfilled, (state, action) => {
        state.forecast.status = "fulfilled";
        state.forecast.result = action.payload.message;
        if (action.payload.task_ids.length > 0) {
          let inProcess = state.forecastInProcess.filter(x => x.personId !== action.meta.arg)
          inProcess.push({personId: action.meta.arg, taskId: action.payload.task_ids[0]})
          state.forecastInProcess = inProcess
        }
        state.render += 1;
      })
      .addCase(makeForecast.pending, (state) => {
        state.forecast.status = "pending";
        state.forecast.error = "";
      })
      .addCase(makeForecast.rejected, (state, action) => {
        const error = action.payload as IApplicationAPI.makeForecast.Error;
        state.forecast.status = "rejected";
        state.forecast.result = "";
        state.forecast.error = getMessageFromDefaultResponseError(error);
      })
      .addCase(getForecastStatus.fulfilled, (state, action) => {
        console.log("fulfilled task", action.payload)
        if (action.payload.status === "completed") {
          state.forecastInProcess = state.forecastInProcess.filter(x => x.taskId !== action.meta.arg)
        }

        if (action.payload.status === "failed") {
          state.forecastInProcess = state.forecastInProcess.filter(x => x.taskId !== action.meta.arg)
        }
      })
      .addCase(getForecastStatus.pending, (state, action) => {
        console.log("pending task", action.meta.arg)
      })
      .addCase(getForecastStatus.rejected, (state, action) => {
        console.log("rejected task", action.meta.arg, action.payload)
      })
  },
});

export const { filterByPerson, filterByModel, resetFilter, swapProfileResults } =
  profileResultSlice.actions;

export default profileResultSlice.reducer;
