import {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useEffect,
  useMemo,
  useState,
  PropsWithChildren,
} from "react";
import tw, { styled } from 'twin.macro';
import { ReactComponent as LogoSVG } from "../../assets/ui/logo.svg";
import { ReactComponent as UserIconSVG } from "../../assets/ui/sidebar_user_icon.svg";
import SidebarDropdown from "./SidebarDropdown";
import { IconNames } from "../ui/icons/Icon";
import { Transition } from "react-transition-group";
import SidebarButton from "../ui/buttons/SidebarButton";
import { ButtonStyles } from "../ui/buttons/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { ReduxState, useReduxDispatch } from "../../redux/store";
import { logout } from "../../redux/slices/authSlice";
import { useSelector } from "react-redux";
import { SidebarDropdownOptionProps } from "./SidebarDropdownOption";
import {
  filterByModel,
  filterByPerson,
  getProfileResults,
  resetFilter,
} from "../../redux/slices/profileResultSlice";
import { version } from '../../utils/config'


interface SidebarProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const Version = styled.i(() => [
  tw`
    text-xs text-light font-medium not-italic text-right
    relative inline-block w-full top-[-55px]
  `
])

const Sidebar: FC<SidebarProps> = ({ show, setShow }) => {
  const location = useLocation();
  const { persons, render: personRender } = useSelector(
    (state: ReduxState) => state.persons,
  );
  const { filterValue, render: resultsRender } = useSelector(
    (state: ReduxState) => state.profileResults,
  );

  const [loading, setLoading] = useState<boolean>(false);

  const { mlmodels } = useSelector((state: ReduxState) => state.mlmodels);
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const data = await Promise.all([
        dispatch(getProfileResults(filterValue)),
        // dispatch(getPersons()),
        // dispatch(getMlmodels()),
      ]);

      return data;
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    setLoading(true);
    getData().then((data: any) => {
      console.log("profileResult", data)
      const payload: number = data ? data[0].payload?.count : 0;
      if (payload === 0) {
        navigate("/home/application-form");
      }
    });
    setLoading(false);
  }, [personRender, resultsRender]);

  const dropdownPersons = useMemo(
    (): SidebarDropdownOptionProps[] =>
      persons.map((person) => ({
        id: person.id,
        label: person.avatar_name,
        icon: IconNames.USERS,
        onClick: () => {
          setShow(false);
          dispatch(filterByPerson(person.id));
        },
        active: filterValue.person.indexOf(person.id) !== -1,
      })),
    [persons, filterValue],
  );

  const dropdownMlmodels = useMemo((): SidebarDropdownOptionProps[] => {
    const mlmodelOptions = mlmodels.map((model) => ({
      id: model.id,
      label: model.name,
      pic: model.pic,
      onClick: () => {
        dispatch(filterByModel(model.id));
        setShow(false);
      },
      active: filterValue.model.indexOf(model.id)!== -1,
    }));
    const dropdownOptions = [
      {
        id: "results",
        label: 'Итоги',
        icon: IconNames.RESULTS,
        onClick: () => {
          dispatch(filterByModel("results"));
          setShow(false);
        },
        active: filterValue.model.indexOf('results') !== -1,
      },
      ...mlmodelOptions,
      {
        id: "all",
        label: "Все",
        icon: IconNames.USERS,
        onClick: () => {
          dispatch(filterByModel(""));
          setShow(false);
        },
        active: filterValue.model.length === 0,
      },
    ];

    return dropdownOptions;
  }, [mlmodels, filterValue]);

  return (
    <>
      <Transition in={show} timeout={200} unmountOnExit>
        {(state) => (
          <div
            style={{
              backgroundColor: "rgba(0, 0, 0, .5)",
            }}
            className={`
            ${state === "entered" ? "opacity-100" : "opacity-0"}
            flex
            fixed
            transition-opacity
            top-0
            bottom-0
            cursor-pointer
            left-0
            right-0
            bg-black
            z-40
            md:hidden
          `}
            onClick={() => setShow(false)}
          />
        )}
      </Transition>

      <div
        className={`
        flex
        flex-col
        min-w-[304px]
        max-w-[304px]
        h-full
        w-full
        z-50
        items-center
        ease-in-out 
        overflow-y-auto
        duration-300
        transition-transform
        transform
        bg-white
        bg-gradient-to-b from-purple-600 to-purple-500
        ${show ? "translate-x-0" : "-translate-x-[304px]"}
        fixed
        md:translate-x-0
        md:relative
        md:z-0
      `}
      >
        <div
          className="
          flex
          flex-col
          px-[34px]
          mb-[37px]
        "
        >
          <div
            onClick={() => navigate("/home/dashboard/")}
            className="hover:cursor-pointer"
          >
            <LogoSVG className="w-full" />
            <Version>{version}</Version>
          </div>
          <UserIconSVG className="w-full mb-[37px]" />
          <div
            className="
            flex
            flex-col
            w-full
            gap-[47px]
          "
          >
            <SidebarDropdown label="Мои сервисы" options={dropdownMlmodels} />
            <SidebarDropdown label="Мои Тропы" options={dropdownPersons} />
          </div>
        </div>
        {(filterValue.model.length > 0 || filterValue.person.length > 0) && (
          <SidebarButton
            onClick={() => dispatch(resetFilter())}
            title="сбросить фильтр"
            icon={IconNames.REFRESH}
            iconStyle="fill-red-700 h-[31px]"
            color={ButtonStyles.ERROR_SECONDARY}
          >
            Сбросить фильтр
          </SidebarButton>
        )}
        {location.pathname !== "/home/avatar-form" && (
          <SidebarButton
            onClick={() => navigate("/home/avatar-form")}
            icon={IconNames.SUN}
            iconStyle="fill-purple-primary h-[31px]"
            color={ButtonStyles.LIGHT}
          >
            Другая Тропа
          </SidebarButton>
        )}

        <SidebarButton
          onClick={() => dispatch(logout())}
          title="Выйти из профиля"
          icon={IconNames.EXIT}
          iconStyle="fill-red-700 h-[31px]"
          color={ButtonStyles.ERROR_SECONDARY}
        >
          Выйти из профиля
        </SidebarButton>
      </div>
    </>
  );
};

export default memo(Sidebar);
