import React, { FC, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/Navbar";
import { useLocation } from 'react-router-dom'
import Footer from '../../components/ui/Footer'
import { loadMoreProfileResults } from "../../redux/slices/profileResultSlice";
import { ReduxState, useReduxDispatch } from "../../redux/store";

const ForecastLayout: FC = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const ref = useRef<HTMLDivElement>(null)

  const dispatch = useReduxDispatch();
  const { next, loadMoreStatus, status } = useSelector(
    (state: ReduxState) => state.profileResults,
  );

  const handleScroll = (e: any) => {
    if (!e.target
      || !next
      || loadMoreStatus === 'pending'
      || status === 'pending'
    )
      return
    if (
      e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - 300
    ) {
      dispatch(loadMoreProfileResults(next))
    }
  }

  setTimeout(() => {
    if (
      ref !== null
      && next
      && ref.current
      && ref.current.scrollTop === 0
      && loadMoreStatus !== 'pending'
      && status !== 'pending'
    ) {
      if (
        ref.current.offsetHeight + ref.current.scrollTop > ref.current.scrollHeight - 300
      ) {
        dispatch(loadMoreProfileResults(next))
      }
    }
  }, 0)

  return (
    <div className="flex h-screen w-[100vw]">
      <Sidebar show={showSidebar} setShow={setShowSidebar} />
      <div ref={ref} className="w-full overflow-y-scroll" onScroll={handleScroll} >
        <div className={`
          px-sm max-w-[1600px] mx-auto md:px-md lg:px-lg
          min-h-screen
          flex flex-col justify-between items-start gap-md
          overflow-x-hidden
        `}>
          <div className="w-full">
            <Navbar showSidebar={setShowSidebar} />
            <Outlet />
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  );
};

export default ForecastLayout;
