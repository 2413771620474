import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useSelector } from "react-redux"

import { ReduxState, useReduxDispatch } from "../../redux/store"
import { getPersons } from "../../redux/slices/personsSlice"
import { getMlmodels } from "../../redux/slices/mlmodelSlice"
import { getForecastStatus } from '../../redux/slices/profileResultSlice'

const HomeLayout = () => {
  // load mlmodels and persons
  const dispatch = useReduxDispatch();
  const getData = async () => {
    try {
      const data = await Promise.all([
        dispatch(getPersons()),
        dispatch(getMlmodels()),
      ])
      
      return data
    } catch (err) {
      console.log("error load", err)
    }
  }
  const { persons, render: personRender } = useSelector(
    (state: ReduxState) => state.persons,
  )
  const { filterValue, render: resultsRender, forecastInProcess } = useSelector(
    (state: ReduxState) => state.profileResults,
  )

  useEffect(() => {
    getData().then((data: any) => {})
  }, [personRender, resultsRender])

  // useEffect(() => {
  //   let id: null | NodeJS.Timer = null
  //   if (forecastInProcess.length > 0) {
  //     id = setInterval(() => {
  //       if (forecastInProcess.length === 0 && id) {
  //         clearInterval(id)
  //       }
  //       forecastInProcess.forEach((item) => {
  //         dispatch(getForecastStatus(item.taskId))
  //       })
  //     }, 60000)
  //   }
  //   return () => {
  //     if (id)
  //       clearInterval(id)
  //   }
  // }, [forecastInProcess, resultsRender])

  return <Outlet />
};

export default HomeLayout;
